input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  font-size: $input-fz;
}

// Might be input or textarea
.form-field-container {
  min-width: 288px;
  width: 100%;
  min-height: 40px;
  padding-top: 10px;
  margin-top: -10px;
  margin-bottom: $input-margin-bottom;
  overflow: hidden;
}

.form-field {
  border-radius: $input-border-radius;
  border: solid 1px $input-border-color;
  background: $white;

  input {
    width: 100%;
    padding: 0 15px;
    border-radius: 4px;
  }

  textarea {
    width: 100%;
    border-radius: 4px;
  }

  select {
    @include calc(width, '100% + 20px');
    padding: 0 60px 0 12px;

    &:invalid,
    &.is-empty {

      &,
      &:focus {
        color: $placeholder-color;
      }
    }
  }

  input,
  select,
  textarea {
    min-height: 40px;
    background-color: transparent;
    border: none;
    font-size: $input-fz;
    line-height: $input-lh;
    outline: none;
    padding: 0 15px;

    @include placeholder() {
      color: $placeholder-color;
    }

    &~label {
      background: $white;
      color: $label-color;
      margin: 0;
      display: block;
      text-align-last: left;
      min-width: 0;
      position: absolute;
      top: 8px;
      left: 15px;
      font-size: 16px;
      font-weight: 400;
      @include transition(150ms ease-in-out);
      padding-right: 99px;
    }

    &.active~label {
      top: -8px;
      left: 12px;
      font-weight: 500;
      padding: 0 4px;
      color: #7C92B3;
      font-size: 12px;
      padding-right: 4px
    }

    &:focus~label {
      top: -8px;
      left: 12px;
      font-weight: 500;
      padding: 0 4px;
      color: $input-focused-label-color;
      font-size: 12px;
      width: max-content;
    }
  }

  textarea {
    display: block;
    min-height: 100px;
    padding: 8px 15px;
  }

  &__select {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -21px;
      width: 20px;
      z-index: 2;
      background-color: $white;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      right: 12px;
      width: 24px;
      height: 24px;
      z-index: 0;
      // background-image: url('../../assets/images/icons/arrow_down_filled.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    select {
      position: relative;
      z-index: 1;
    }
  }

  &:focus-within {
    border-color: $input-focused-border-color;
  }

  &.is-error {
    border-color: $danger;

    label,
    &:hover label,
    &:focus label,
    &:active label,
    &.active label {
      color: $danger !important;
    }
  }

  &.is-success {
    border-color: $success;

    label {
      color: $success;
    }
  }
}

textarea {
  min-height: 100px;
  resize: none;
}

// CHECKBOX AND RADIO
.checkbox-label {
  border-radius: $input-border-radius;
}

.square,
.circle {
  width: 20px;
  height: 20px;
  border-radius: $input-border-radius;

  @include transition(border-color 200ms, background-color 200ms);
}

.circle {
  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transform: scale(0, 0);
    background-color: $primary;

    @include transition(transform 200ms);
  }
}

// CHECKBOX
.checkbox {
  &:checked {
    &+.square {
      background-color: $primary;
      border-color: $primary;
    }

    &:disabled {
      &+.square {
        background-color: $disabled;
      }
    }
  }

  &:disabled {
    &+.square {
      border-color: $disabled;
    }

    &~.checkbox-text {
      color: $disabled;
    }
  }
}

// RADIO
.radio {
  &:checked {
    &+.circle {
      border-color: $primary;

      &::before {
        transform: scale(1, 1);
      }
    }
  }
}

.custom-control-input {
  width: 20px;
  height: 20px
}

.custom-control-label {

  &:before,
  &:after {
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }

  &.is-small {

    &:before,
    &:after {
      width: 18px;
      height: 18px;
      margin-top: -2px;
    }
  }

  &.is-indeterminate {
    &:after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e") !important;
    }
  }
}

.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}

.youtube-form {
  position: relative;

  .add-btn {
    height: 40px;
    width: 40px;
    text-align: center;
    border: none;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      background-color: $input-border-color;
      left: 0;
      top: 3px;
      bottom: 3px;
    }

    &:not(:disabled) {
      background-color: #fff;
    }
  }
}

.select-dropdown {
  border-top: 6px solid #5867DD;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}


.drop-multi-select {
  height: 40px;
  position: relative;
  border: 1px solid #D4DAE8;
  border-radius: 4px;

  .active-item {
    padding: 8px 12px;
    font-size: 16px;
    line-height: 21px;
  }

  .drop-menu {
    font-size: 14px;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    border: 1px solid #D4DAE8;
    border-radius: 4px;
    width: calc(100% + 4px);
    margin-top: 4px;
    margin-left: -2px;
    z-index: 1000;

    .drop-title {
      padding: 12px 16px;
      color: #808B9C;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px solid #E6EBF5;
    }

    ul {
      max-height: 150px;
      overflow: scroll;
      padding: 6px 16px;

      li {
        margin: 8px 0;

        label {
          padding-left: 5px
        }
      }
    }

    .drop-footer {
      border-top: 1px solid #E6EBF5;
      padding: 13px 16px;
    }
  }

  &.active {
    border-color: #5569DA;

    .drop-menu {
      display: block;
    }
  }
}

.form-field-with-dropdown {
  @extend .d-flex;

  .form-field-container {
    min-width: unset;

    // Input
    &:first-child {
      max-width: 65%;

      .form-field {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        input {
          min-height: 38px;
        }
      }
    }

    // Dropdown
    &:last-child {

      &>div>div>div {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 0;


        &:hover {
          border-color: #D4DCE7;
        }
      }
    }
  }
}
