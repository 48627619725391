html,
body {
  @include font-smoothing(antialiased);
}

.movies {
  margin: 0 -7.5px;
}

.movie-box-container {
  padding: 0 7.5px;
}

.movie-box {
  background:#fff;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 15px;

  .img-area {
    background-color: #E0E3EA;
    padding-top: 60%;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-top-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  &.selected {
    color: #5867DD;
  }
  &.is-disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.search-box {
  border: 1px solid #AABAD1;
  border-radius: 3px;
  background-color: #fff;
  padding-left: 16px;
  &:focus-within {
    border-color: $primary;
  }
  input {
    padding-right: 42px;
    line-height: 34px;
    border: none;
    background-color: transparent;
    outline: none;

    @include placeholder {
      color: #7C92B3;
      font-size: 14px;
    }
  }
  .clear-search {
    position: absolute;
    right: 10px;
  }
}

.sort-box {
  border-radius: 3px;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    border: none;
    background-color: transparent;
    outline: none;
    color: #28436C;

    &::-ms-expand {
      display: none;
    }
  }

  button {
    border: none;
    width: 8px;
    height: 22px;
    background-color: transparent;
    position: relative;
    outline: none;

    .arrow {
      position: absolute;
      left: 4px;
      &-up {
        top: 0;
      }
      &-down {
        bottom: 0;
      }
    }
  }
}

.sort-box {
  min-width: 200px;
  position: relative;

  .sort-header {
    height: 46px;
    padding: 0 15px;
    color: #28436C;
    user-select: none;
    cursor: pointer;
    background-color: #E5E6EF;

    &:hover {
      background-color: #d0d2e6;
    }
  }

  .sort-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.3);
    overflow: auto;
    max-height: 300px;

    ul {
      padding: 9px 0;
      margin: 0;
      list-style: none;

      li {
        padding: 9px 15px;
        cursor: pointer;

        &:hover {
          background-color: #efeeee;
        }
      }
    }
  }
}

.arrow {
  display: inline-block;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  &-up {
    border-bottom: 8px solid $primary;
  }
  &-down {
    border-top: 8px solid $primary;
  }
}

.dropdown {
  position: absolute;
  box-shadow: 0 0 50px 0 rgba(82,63,105,.15);

  &__down {
    top: 100%;
  }
  &__left {
    right: 0;
  }
}

.header-dropdown {
  .dropdown-toggler .arrow {
    &-down {
      border-top-width: 4px;
      border-bottom: 2px solid transparent;
    }
    &-up {
      border-bottom-width: 4px;
      @include transform(translateY(-2px));
    }
  }
  .dropdown {
    min-width: 350px;
  }
}

.lang-toggle {
  height: 30px;
  background: #fff;
  overflow: hidden;
  font-size: 12px;
  color: #7C92B3;
  border-radius: 15px;
  button {
    border-radius: 15px;
    color: #7C92B3;
    &.active {
      background: #5867DD;
      color: #fff;
    }
  }
}

.upload-zone {
  cursor: pointer;
  transition: 150ms ease-in-out;

  &:hover {
    background-color: rgba(88, 104, 221, 0.1);
  }
}

.select-mark {
  position: absolute;
  top: 10px;
  left: 17.5px;
  z-index: 1;

  img {
    display: none;
  }

  &:not(.selected) {
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #fff;
      display: block;
    }
  }

  &.selected {
    img {
      display: block;
      filter: invert(.9);
      -webkit-filter: invert(.9);
      border-radius: 50%;
    }
    &::before {
      width: 14px;
      height: 14px;
      content: '';
      display: block;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.add-media-block {
  position: relative;

  &.is-multi {
    width: 100%;
    .select-media {
      width: 100%;
      @extend .d-flex;
      overflow: auto;
      img {
        height: initial;
        margin: 0 2px;
      }
    }
  }
}

.select-media {
  width: 220px;
  height: 150px;
  border: solid 3px white;
  background: #f7f7f7;
  box-shadow: 0px 0px 6px #d0d0d0;
  position: relative;

  img {
    width: 220px;
    height: 150px;
    object-fit: cover;
    object-position: center;
    position: relative;
    z-index: 1;
  }

  &::after {
    content: 'Select Medias';
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
    color: #989898;
    font-weight: 100;
    font-size: 14px;
  }
}

.select-btn {
  width: 52px;
  height: 49px;
  border: none;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 2;
}

.select-movie {
  @include media-breakpoint-up(lg) {
    flex: 0 0 14.28%;
  }
}

.scroll-container {
  width: 100%;
  overflow-x: scroll;
  margin: 0;
  padding: 0;
  list-style-type: none;
  scroll-behavior: smooth;
  li {
    border-bottom: 3px solid transparent;
    margin-right: 10px;
    &.active {
      .btn {
        color: #28436C;
      }
      border-color: #FDD100;
    }
    .btn {
      font-weight: 40;
      font-size: 12px;
      color: #7C92B3;
      margin-bottom: 5px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
  }
}

.fz-13 {
  font-size: 13px;
}

.fz-12 {
  font-size: 12px;
}

.fz-14 {
  font-size: 14px;
}

.sub-title {
  font-size: 13px;
  color: #7C92B3;
}

.create-btn {
  &.is-small {
    height: 36px;
    font-size: 13px;
    padding: 7px !important;
    span {
      padding-right: 0 !important;
    }
    img {
      margin-right: 10px !important;
      width: 20px;
    }
  }

  &:disabled {
    opacity: .7;
  }
}

.seasons-list,
.cast-list {
  color: #343537;
  font-size: 14px;
}

.progress {
  position: relative;
  height: 30px;
  .progress-cancel {
    position: absolute;
    top: 0;
    padding: 0;
    height: 100%;
    right: 5px;
    font-size: 13px;
    color: #5867DD;
    font-weight: lighter;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

button.btn-icon {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

button.btn {
  &.btn-outline-blue {
    border-color: #AABAD1;
    color: #7C92B3;
  }
}

.min-40-h {
  min-height: 40px;
}

.media-modal-header {
  p {
    font-size: 13px;
    color: #7C92B3;
    span {
      color: #5867DD;
    }
  }
  button {
    width: 100px;
    font-weight: 100;
  }
}

.media-modal-body {
  height: calc(100vh - 110px);
  overflow: auto;
}

.media-modal-filter {
  .type-filters {
    button {
      color: #6883A9;
      height: 34px;
      font-weight: normal;
      span {
        font-size: 14px;
        margin-left: 5px;
      }
      &:active,&:focus {
        box-shadow: none;
        outline: none;
      }
      &.btn-primary {
        color: #fff;
        span {
          color: #B1B7EF
        }
      }
    }
  }
}

.badge {
  font-size: 11px;
}

.btn-rounded {
  border-radius: 50px;
}

.arrow-btn {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #E3E6ED;
  padding: 0;
  border: none;
  margin-top: 12px;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;

  img {
    width: 7px;
  }

  &:hover {
    background-color: #ced6e8;
  }

  &-prev {
    margin-right: 8px;
  }

  &-next {
    margin-left: 8px;
    img {
      transform: rotateY(180deg);
    }
  }
}

.dropdown-container {
  height: 40px;
  position: relative;
  min-width: 415px;

  .filters {
    border-bottom: 1px solid #D4DCE7;
    position: sticky;
    top: 0;
    z-index: 1;
    background: #FFFFFF;

    .btn {
      width: 33.333%;
      padding: 8px 16px;
      font-size: 16px;
      line-height: 24px;
      border-bottom-width: 1px;
      color: $light;
      height: 40px;

      &.active {
        border-color: $blue-dark;
        color: $blue-dark;
        font-weight: 700;
      }
    }
  }
}

.search-dropdown {
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  .search-box {
    height: 40px;
    border-color: #D4DCE7;
    transition: 150ms ease-in-out;
  }


  .drop-content {
    max-height: 0px;
    overflow: auto;
    transition: 150ms ease-in-out;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid transparent;
    border-top: none;
  }

  .drop-items {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  .drop-item {
    padding: 8px 12px;
    transition: 150ms ease-in-out;
    font-size: 14px;
    line-height: 21px;
    color: $blue-dark;
    cursor: pointer;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .text-ellipsis;

    img {
      width: 72px;
      min-width: 72px;
      height: 40px;
      border-radius: 4px;
      margin-right: 16px;
    }

    &:hover {
      background-color: #E3E8EF;
    }
  }

  &.active {
    .search-box {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .drop-content {
      max-height: 500px;
      box-shadow: 8px 12px 15px rgba(0, 0, 0, 0.15);
      border-color: #D4DCE7;
    }
  }
}


.block-filters {
  button {
    border: none;
    border-radius: 0;
    height: 100%;
    border-bottom: 2px solid transparent;
    padding-bottom: 14px;
    padding-top: 19px;
    color: #4A545F;
    &:hover, &:active, &:focus {
      box-shadow: none;
    }
    &.selected {
      border-color: #5569DA;
      color: #011533;
    }
  }
}

.price-radio-box {
  border: 1px solid #F2F3F8;
  border-radius: 16px;
  height: 104px;
  &.active {
    border: 2px solid #5569DA;
    box-shadow: 0px 4px 8px rgba(85, 105, 218, 0.2);
  }
  label {
    padding: 12px 20px;
    @extend .d-flex;
    @extend .flex-column;
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    font-weight: 500;
    font-size: 16px;
    span {
      margin-bottom: 10px;
    }
    &:before, &:after {
      right: 10px;
      top: 10px;
      left: initial;
    }
  }
}

.mxw-200 {
  max-width: 200px;
}

.freeze {
  position: sticky;
  top: 0;
  background-color: white;
}

.disabled-bg {
  background-color: hsl(0,0%,95%) !important;
}

.max-300-scroll {
  max-height: 300px;
  overflow: scroll;
}

#react-select-portal > div {
  z-index: 100000;
}

.td-desc {
  white-space: unset !important;
  max-width: 100px;
  > div {
    display: -webkit-box;
    max-width: 350px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.cursor-disabled {
  cursor: not-allowed;
}

.cast-and-crew-form-items {
  margin-left: -20px;
}

.cast-crew-remove {
  left: -8px;
  top: 7px;
  z-index: 10;
}

.finance-chart {
  .indicator-content {
    width: 320px;
    .indicator-label {
      font-size: 12px;
    }
  }
}
