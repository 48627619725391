.transform {
  &-y {
    &-12 {
      @include transform(translateY(12px))
    }
    &-20 {
      @include transform(translateY(20px))
    }
  }
}
