.header {
  box-shadow: 0 0 40px 0 rgba(82,63,105,.1);
  height: 58px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background:#fff;
  @include transition(150ms ease-in-out);
  z-index: 2;

  &.no-shadow {
    box-shadow: none;
  }
}
