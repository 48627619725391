.table {
  tr {

    td,
    th {
      font-size: 14px;
      line-height: 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: middle;

      .table-icon {
        width: 8px;
      }
    }
  }

  &-hover {
    tbody {
      tr {
        transition: 150ms ease-in-out;

        &:not(.active):hover {
          background-color: transparentize(#5867dd, .9)
        }

        &.active {
          background-color: #5867dd;
          color: #FFFFFF;
        }
      }
    }
  }
}

.ads-table {
  table-layout: fixed !important;

  td {
    vertical-align: top !important;

    span {
      background-color: #E6EBF5;
    }
  }
}

.ads-new-modal {
  overflow-x: hidden;

  .border-bottom {
    width: 108%;
  }
}

.vahe_modal {
  width: 770px !important;
}

.btn-circle.btn-xl {
  width: 18px;
  height: 18px;
  padding: 0px 16px 22px 8px;
  border-radius: 100%;
  font-size: 14px;
  text-align: center;
}

.text-pt-top {
  padding-top: 3px;
}

.ads-new-name {
  width: 103%;
}

.new-add-country {
  width: 11%;
}

.system-min-width {
  min-width: 0 !important;
}

.count-min-width {
  min-width: 0 !important;
}

.ads-table-new {

  table-layout: fixed !important;

  td {
    border-top: 0px;
    vertical-align: top !important;

    span {
      background-color: #E6EBF5;
      font-size: 13px;
      font-style: normal;
      color: #1B1B1B;
      font-family: Roboto;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
    }
  }
}

.ads-table-new-modal {

  td {
    border-top: 0px;
    vertical-align: top !important;

    span {
      background-color: #E6EBF5;
      font-size: 13px;
      font-style: normal;
      color: #1B1B1B;
      font-family: Roboto;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
    }
  }
}