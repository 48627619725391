@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin font-smoothing($value: antialiased) {
  @if $value ==antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}