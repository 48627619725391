// Main container
.tox-tinymce {
  border: none !important;
}

// Payment Alert
.tox-tinymce-aux {
  .tox-notifications-container {
    display: none !important;
  }
}

// Footer status bar
.tox-statusbar {
  display: none !important;
}

// Editor header
.tox-editor-header {
  display: none !important;

  // @include media-breakpoint-up(md) {
  //   display: block !important;
  // }
}

// Editor wrapper
.tox .tox-pop__dialog {
  border: none !important;
}

// Editor tooltip container
.tox-pop .tox-pop__dialog .tox-toolbar {
  padding: 0 !important;
}

// Editor buttons & dropdowns
.tox {
  .tox-edit-area__iframe {
    background-color: transparent !important;
  }

  .tox-toolbar__group {
    padding: 3px 3px 4px !important
  }

  .tox-tbtn,
  .tox-split-button {
    margin: 0 !important;
    border-radius: 4px !important;
  }
}
