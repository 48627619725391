@include media-breakpoint-down(md) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  p, .size-p {
    font-size: 16px;
  }

}

h1, h2, h3 {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.weight-100 { font-weight: 100 !important }
.weight-200 { font-weight: 200 !important }
.weight-300 { font-weight: 300 !important }
.weight-400 { font-weight: 400 !important }
.weight-500 { font-weight: 500 !important }
.weight-600 { font-weight: 600 !important }
.weight-700 { font-weight: 700 !important }
.weight-800 { font-weight: 800 !important }
.weight-900 { font-weight: 900 !important }

.text {
  &-xxs {
    font-size: 8px;
  }
  &-xs {
    font-size: 11px;
    line-height: 15px;
  }
  &-sm {
    font-size: 12px;
    line-height: 25px;
  }

  &-md {
    font-size: 15px;
    line-height: 25px;
  }

  &-lg {
    font-size: 18px;
    line-height: 24px;
  }

  &-primary__light {
    color: $primary-light
  }
  &-grey__light {
    color: $grey-light
  }
  &-light {
    color: $light;
  }
  &-blue-dark {
    color: $blue-dark !important;
  }
}

.bg {
  &-primary__light {
    background-color: $primary-light
  }
  &-grey__light {
    background-color: $grey-light
  }
  &-purple__light {
    background-color: #E5E6EF;
  }
  &-blue-dark {
    background-color: $blue-dark !important;
  }
}
