.t {
  &-0 {
    top: 0px;
  }
  &-4 {
    top: 4px;
  }
  &-8 {
    top: 8px;
  }
  &-12 {
    top: 12px;
  }
  &-16 {
    top: 16px;
  }

  &-md-none {
    @include media-breakpoint-up(md) {
      top: unset;
    }
  }
}

.l {
  &-0 {
    left: 0px;
  }
  &-4 {
    left: 4px;
  }
  &-8 {
    left: 8px;
  }
  &-12 {
    left: 12px;
  }
  &-16 {
    left: 16px;
  }
  &-30 {
    left: 30px;
  }

  &-md-30 {
    @include media-breakpoint-up(md) {
      left: 30px;
    }
  }
}

.r {
  &-0 {
    right: 0px;
  }
  &-4 {
    right: 4px;
  }
  &-8 {
    right: 8px;
  }
  &-12 {
    right: 12px;
  }
  &-16 {
    right: 16px;
  }
}

.b {
  &-0 {
    bottom: 0px;
  }
  &-4 {
    bottom: 4px;
  }
  &-8 {
    bottom: 8px;
  }
  &-12 {
    bottom: 12px;
  }
  &-16 {
    bottom: 16px;
  }
  &-30 {
    bottom: 30px;
  }

  &-md-30 {
    @include media-breakpoint-up(md) {
      bottom: 30px;
    }
  }
}
