.signin-page {
  .left-part {
    min-height: 200px;
    background-color: rgb(0, 16, 47);
  }
  p {
    font-size: 13px;
    color: #ffffff66;
  }
}
