.countries-list {
  font-size: 14px;
  line-height: 20px;
  color: #4A545F;
  margin-left: -33px;
  margin-right: -33px;
  .country-item {
    margin-bottom: 10px;
  }
  .continent-item {
    margin: 18px 0;
    font-weight: 500;
  }

  .custom-checkbox {
    padding-left: 22px;
    input:checked + label {
      color: #313438;
    }
    label {
      position: relative;
      padding-top: 1px;
      .indeterminate-indicator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}