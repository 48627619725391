.sidebar {
  background-color: #011533;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: all .2s ease;
  width: 62px;
  z-index: 1000;
  color: #fff;
  @include transition(150ms ease-in-out);

  &.active {
    width: 265px;
  }

  .sidebar-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 15px;
    cursor: pointer;
    @include transition(150ms ease-in-out);

    &:hover {
      filter: invert(1);
      -webkit-filter: invert(1);
    }
  }

  &:not(.active) {
    &~.page-container {
      padding-left: 62px;
    }
    .logo {
      display: none
    }
    .sidebar-toggle {
      @include rotate(180);
      right: 8px;
    }
    .nav-passive {
      filter: invert(.3);
      -webkit-filter: invert(.3);
    }
  }

  .sidebar-header {
    padding: 0 25px;
    background-color: #01122C;
    height: 65px;
  }

  .sidebar-content {
    padding-top: 30px;
    height: calc(100% - 65px);
    overflow: auto;
    a {
      padding: 9px 25px;
      height: 44px;
      font-size: 13px;
      font-weight: 400;
      text-decoration: none;
      color: #7C92B3;

      .count {
        color: #28436C
      }

      &.active {
        color: #7C92B3;

        .count {
          color: #7C92B3;
        }
      }

      img {
        margin-right: 20px;
        width: 17px;
      }

      .nav-active {
        display: none;
      }
      &.active, &:hover {
        background-color: #01122C;
        color: #fff;
        .nav-passive {
          display: none;
        }
        .nav-active {
          display: inline-block;
        }
      }
    }

    .custom_icons {
      width: 25px;
      height: 25px;
      margin: -1px 15px 0px -3px;
    }

    .custom_promo {
      width: 22px;
      height: 22px;
      margin: -1px 17px 1px -2px;
    }

    p {
      margin: 20px 0 0 0;
      padding: 0 25px;
      color: #28436C;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: .3px;
      line-height: 40px;
    }
  }
}
