@import "mixins/main";

@import "utils/main";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~bootstrap/scss/bootstrap.scss";
@import "~react-dates/lib/css/_datepicker.css";
@import "~react-datepicker/dist/react-datepicker.css";

@import "fonts/main";

@import "reboot";

@import "libs/main";

@import "common/main";

@import "layouts/main";

@import "pages/main";


.page-container {
  min-height: 100vh;
  padding-left: 265px;
  transition: all 0.2s ease;
  @extend .d-flex;
  @extend .flex-column;
}

.main-content {
  padding: 30px;
  // min-height: 100vh;
  background: #f1f2f7;
  @extend .flex-fill;
}
