.btn {
  &-icon {
    &:not(:disabled):not(.disabled) {
      &:hover img {
        filter: invert(1);
        -webkit-filter: invert(1);
      }
    }
  }

  &-sm {
    line-height: 15px;
  }

  &-clean {
    background-color: transparent;

    &:hover {
      background-color: rgba(77, 89, 149, .06);
    }
  }

  &-crud {
    height: 30px;
    min-width: 100px;
  }

  &-no-shadow {

    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
    }
  }
}

.back-btn {
  background-color: #E3E6ED;
  color: #1A3C6F;
  border: none;
  padding: 4px 13px;

  img {
    width: 7px;
    margin-top: -1px;
    margin-right: 2px;
  }

  &:hover {
    background-color: #ced6e8;
  }
}

.filters {
  .btn {
    border: none;
    border-radius: 0;
    border-bottom: 3px solid transparent;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #28436C;
    opacity: 0.6;

    &.active {
      border-bottom-color: $yellow;
      opacity: 1;
      font-weight: 700;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.publish-btn {
  border: 1px solid #5868dd;
  background: #FFF;
  color: #5868dd;
  width: 160px;
  height: 100px;
  border-radius: 10px;
  transition: 150ms ease;
  cursor: pointer;
  margin-right: 20px;

  &:hover {
    background-color: #5868dd2b;
  }

  &.active {
    background-color: #5868dd;
    color: #FFF;
  }
}

.ameria img {
  width: 19px !important;
  margin-right: 10px !important;
}

.addNewGroups {
  span {
    color: #5867DD !important;
  }
}
