// [Color Palette]
$black: #0D0E10;
$white: #FFFFFF;
$yellow: #FDD100;
$red: #FF2E2E;
$extra-dark: #18181A;
$light: #7C92B3;
$dark: #15181F;
$grey-light: #E5E6EF;
$grey-dark: #575757;
$grey: #BEBEBE;
$blue-dark: #1A3C6F;

$disabled: #DDDDDD;


$primary: #5867DD;
$primary-light: #6883A9;
$danger: $red;

$default-border-color: #D7D9E3;

// [Main Definitions]
// $body-bg: $black;
// $body-color: $white;

// [Typography]
$font-family-base: 'Roboto', sans-serif;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 4 !default;
$h2-font-size:                $font-size-base * 2.625 !default;
$h3-font-size:                $font-size-base * 2.25 !default;
$h4-font-size:                $font-size-base * 1.625 !default;

// [Fields]
$input-margin-bottom:         18px;
$placeholder-color:         rgba(14, 31, 63, 0.38);
$input-fz:                    $font-size-base;
$input-lh:                    $line-height-base;
$label-color:               #7C92B3;
$input-active-border-color:           #D4DCE7;
$input-active-label-color:            #D4DCE7;
$input-focused-border-color:          $primary;
$input-focused-label-color:           $primary;
$navbar-item-default-color:           $grey;

// [Breakpoints & Container Width]
$bp-xs: 0;
$bp-sm: 768px;
$bp-md: 1024px;
$bp-lg: 1280px;
$bp-xl: 1920px;

$cw-md: $bp-md;
$cw-lg: $bp-lg;
$cw-xl: $bp-xl;

// [Button Definitions]
$btn-border-radius: 4px;
$btn-font-weight: bold;
$btn-padding-y: 16px;
$btn-padding-x: 40px;
$btn-font-size: 18px;
$btn-line-height: 22px;

// [Form & Field Definitions]
$input-color: #000;
$input-bg: #fff;
$input-disabled-bg: rgba(0,0,0,.1);
$input-border-color: #D4DCE7;
$input-border-radius: 5px;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-padding-x: 1.25rem;
$input-padding-y: 1rem;
$input-line-height: 1.4;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: #5867DD;
$input-placeholder-color: #7C92B3;

// [Theme Configurations]
$theme-colors: (
  "primary": $primary,
  "danger": $danger,
);

$spacer: 5px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 1), // 5px
    2: ($spacer * 2), // 10px
    3: ($spacer * 3), // 15px
    4: ($spacer * 4), // 20px
    5: ($spacer * 5), // 25px;
    6: ($spacer * 6), // 30px;
    7: ($spacer * 7), // 35px;
    8: ($spacer * 8), // 40px;
    9: ($spacer * 9), // 45px;
    10: ($spacer * 10), // 50px;
    11: ($spacer * 11), // 55px;
    12: ($spacer * 12), // 60px;
    13: ($spacer * 13), // 65px;
    14: ($spacer * 14), // 70px;
    15: ($spacer * 15), // 75px;
  ),
  $spacers
);

$grid-breakpoints: (
  xs: $bp-xs,
  sm: $bp-sm,
  md: $bp-md,
  lg: $bp-lg,
  xl: $bp-xl,
);

$container-max-widths: (
  md: $cw-md,
  lg: $cw-lg,
  xl: $cw-xl,
);

