.filter-item {
  padding: 8px;
  background: #FFFFFF;
  border: 1px solid #E1E6FF;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  transition: 150ms;
  min-height: 37px;
  cursor: pointer;
  @extend .text-blue-dark;
  @extend .d-inline-flex;
  @extend .align-items-center;

  &:focus {
    outline: none;
  }

  &.disabled {
    position: relative;
    cursor: default;

    &::after, .dropdown-list:after {
      content: '';
      display: block;
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-color: rgba(255, 255, 255, .5);
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pie-chart {
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.show-more-btn {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.indicator-container {
  cursor: pointer;
  position: relative;
  max-width: max-content;

  * {
    transition: 150ms ease-in-out;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    left: -9px;
    right: -9px;
    bottom: -3px;
    border-radius: 4px;
    border: 1px solid transparent
  }

  &:hover::after {
    border-color: #e2e2e2;
  }

  &-disabled * {
    opacity: .4;
  }

  &-sm::after {
    left: -3px;
    right: -3px;
  }
}

.indicator-content {
  width: 150px;
  padding-right: 16px;
  @extend .d-inline-flex;
  @extend .align-items-center;
}

.indicator-square {
  display: inline-block;
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 1px;
}

.indicator-label {
  font-size: 14px;
  line-height: 21px;
}

.analytics-dropdown {
  position: relative;
  user-select: none;

  .dropdown-list {
    max-height: 0;
    height: auto;
    transition: max-height 150ms ease-in-out;
    border: 1px solid #E1E6FF;
    background-color: #FFFFFF;
    overflow: auto;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    z-index: 1;
    border-top-width: 0;
    border-bottom-width: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0;

    &__dense {
      transform: translate(-1px, 1px);
      width: calc(100% + 2px);
    }

    .dropdown-item {
      padding: 8px;
      font-size: 14px;
      line-height: 21px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      @extend .text-light;

      &:not(:last-child) {
        border-bottom: 1px solid #FFFFFF;
      }

      &:hover {
        background-color: #f0f6fe;
      }

      &:active {
        background-color: #f0f6fe;
      }

      &__range {
        &, &:hover, &:active {
          background-color: #FFFFFF;
        }
      }

      &__selected {
        position: relative;
        &, &:hover, &:active {
          font-weight: 600;
          background-color: #f0f6fe;
        }
      }
    }
  }

  &.open {
    &, .filter-item {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .dropdown-list {
      max-height: 330px;
      border-bottom-width: 1px;
    }
  }
}

.flip-x {
  transform: rotateX(180deg);
}

.transition-150 {
  transition: 150ms ease-in-out;
}

.filter-remove-btn {
  cursor: pointer;

  &:hover {
    path:first-child {
      fill: $primary
    }
  }
}

.reset-filter-btn {
  font-size: 14px;
  line-height: 21px;
  padding: 8px 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.selected-filters-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  @extend .d-inline-flex;
  @extend .align-items-center;
}

.download-btn {
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  @extend .d-inline-flex;
  @extend .align-items-center;
}

// Date range
.DateRangePickerInput__withBorder {
  border: none;
  border-radius: 4px;
}

.DateInput {
  border: 1px solid #E1E6FF;
  border-radius: 4px;
  overflow: hidden;
  width: 100px;
}

.DateInput_input {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: none;
  color: #6883A9;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;

  &::-webkit-input-placeholder {
    color: #6883A9;
  }
}

.DateRangePickerInput_arrow_svg {
  width: 10px;
  opacity: 0;
}

.chart-container {
  height: calc((100vh - 196px) / 2);
  min-height: 323px;
}

.media-badge {
  font-size: 14px;
  line-height: 21px;
  background-color: #7C92B3;
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
  margin-right: 6px;
}

.radius-4 {
  border-radius: 4px;
}

.media-tabs {
  position: absolute;
  top: 127px;
  left: 295px;
  right: 30px;
}

.bar-chart-container {
  height: calc(100vh - 470px);
  // min-height: 300px;
  // max-height: 400px;
}

.h-individual-size {
  height: calc(100vh - 600px);
  min-height: 300px;
}

.mh-788 {
  min-height: 788px
}

.mt-85 {
  margin-top: 55px !important;
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px;
}

.w-40 {
  width: 40px;
}
.w-78 {
  width: 78px;
}

.w-105 {
  width: 105px;
}

.w-140 {
  width: 140px;
}

.w-200 {
  width: 200px;
}

.mw-50 {
  min-width: 50px;
}

.mw-80 {
  min-width: 80px;
}

.mw-105 {
  min-width: 105px;
}

.mw-125 {
  min-width: 125px;
}

.mw-130 {
  min-width: 130px;
}

.mw-150 {
  min-width: 150px;
}

.mw-230 {
  min-width: 230px;
}

.mh-57 {
  min-height: 57px;
}

.mh-124 {
  min-height: 124px;
}

.mh-585 {
  min-height: 585px;
}

.bg-transparent-white {
  background-color: rgba(255, 255, 255, .5);
}

.bg-white {
  background-color: #ffffff;
}

.z-index-2 {
  z-index: 2;
}

.h-500-px {
  height: 500px;
}

.mxh-335 {
  max-height: 335px
}
