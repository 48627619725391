.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;

  .modal-title {
    margin: -33px -33px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .modal-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 34px 0 rgba(0,0,0,0.2);
    width: 689px;
    padding: 33px;
    transition: 300ms ease-out;
    z-index: 7;
    max-height: calc(100vh - 30px);
    overflow-y: auto;

    &.auto-height {
      min-height: inherit!important;
    }
  }

  &.modal_type_full_right {

    .modal-overflow-blue {
      background: rgba(#01122C, 0.6);
      backdrop-filter: none;
    }

    .modal-content {
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
      padding: 30px;
      position: fixed;
      top: 0;
      height: 100%;
      margin: 0 !important;
      right: 0;
      box-shadow: inset 0px -1px 0px #d8d8e2;
      border-radius: 4px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      .modal-title {
        margin: -30px -30px 15px;
        border-top-left-radius: 4px;
        border-top-right-radius: 0px;
      }
    }
  }

  .modal-header {
    margin: -48px -48px 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .modal-overflow-blue {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    // backdrop-filter: blur(6px) brightness(60%) contrast(40%);
  }

  .modal-dismiss {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 25px;
      height: 3px;
      background-color: #ccc;
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform-origin(center);
    }
    &::before {
      @include transform(translate(-50%, -50%) rotate(45deg))
    }
    &::after {
      @include transform(translate(-50%, -50%) rotate(-45deg))
    }

    &:hover {
      &::before,
      &::after {
        background-color: #b9b6b6;
      }
    }
  }

  &__lg .modal-content {
    min-width: 1024px;
    min-height: 600px;
  }
  &__md .modal-content {
    // width: 940px;
    min-height: 350px;
  }
  &__sm .modal-content {
    // width: 520px;
    min-height: 300px;
  }
  &__xs .modal-content {
    // width: 520px;
    min-height: 0;
  }
  &__full {
    z-index: 10000;
    .modal-content {
      overflow: auto;
      width: 100%;
      height: calc(100vh - 0px);
      margin: 0 !important;
      border-radius: 0;
      border: 0;
      background-color: #f1f2f7 !important;
      padding: 25px 40px;
    }
  }

}

.modal-media {
  iframe, video {
    min-width: 100%;
    max-height: 100%;
    border: none;
  }
}

.modal-media-wrapper {
  .modal-overflow-blue {
    backdrop-filter: none;
  }
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 22px;
  padding: 0;
  border: none;
  background: transparent;

  &, &:focus, &.active {
    outline: none;
    box-shadow: none;
  }
}

.react-confirm-alert-overlay {
  z-index: 10000 !important;
  background: rgba(1, 18, 44, 0.65) !important;

  .react-confirm-alert {
    background-color: $white;
    border-radius: 3px;
  }
}

.categories-modal {
  max-height: 450px;
  overflow: auto;

  .add-input {
    .form-field {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .add-btn {
    padding-top: 10px;
    padding-bottom: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ctg-input {
    .form-field {
      border-width: 0;
      input {
        padding: 0;
      }
    }
  }
}

.confirm-modal.confirm-publish {
  width: 420px;
  .modal-body {
    text-align: left;
    p {
      font-size: 13px;
      color: #343537;
      margin-top: 10px !important;
      margin-bottom: 15px !important;
    }
  }
  .publish-options {
    p {
      color: #7C92B3;
      font-size: 13px;
    }
    label, span {
      font-size: 13px;
      color: #343537;
    }
  }
  .drm-select {
    margin-top: 20px;
  }
  .custom-control {
    margin-right: 33px !important;
  }
  .custom-control-label:before, .custom-control-label:after {
    margin-top: -5px;
  }
  .modal-footer {
    border-top: 0;
    button.btn {
      padding: 2px;
      font-size: 12px;
    }
  }
}

.plan-manage-modal {
  .subscription-item-wrapper {
    margin-bottom: 8px;
  }
  .subscription-item {
    padding: 7px 10px;
    font-size: 14px;
    .plan-status {
      width: 8px;
      height: 8px;
      display: block;
      border-radius: 100%;
      background: #F31935;
      &.active {
        background: #33A947;
      }
    }
  }
}

.modal-scroll-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 250px);
}
