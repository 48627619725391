// [Global Classes]
.no-list-style {
  list-style: none;
}

.pointer {
  cursor: pointer;
}

.op-1 { opacity: .1 }
.op-2 { opacity: .2 }
.op-3 { opacity: .3 }
.op-4 { opacity: .4 }
.op-5 { opacity: .5 }
.op-6 { opacity: .6 }
.op-7 { opacity: .7 }
.op-8 { opacity: .8 }
.op-9 { opacity: .9 }
.op-10 { opacity: 1 }

.z-1 { z-index: 1 }
.z-2 { z-index: 2 }
.z-3 { z-index: 3 }
.z-4 { z-index: 4 }
.z-5 { z-index: 5 }
.z-6 { z-index: 6 }
.z-7 { z-index: 7 }
.z-8 { z-index: 8 }
.z-9 { z-index: 9 }

.mnw-auto { min-width: auto !important }
.mnw-20 { min-width: 20px }
.mnw-30 { min-width: 30px }
.mnw-80 { min-width: 80px }
.mnw-100 { min-width: 100px }
.mnw-100-p { min-width: 100% !important }
.mnw-110 { min-width: 110px }

.col-lg-2_5 {
  @include media-breakpoint-only(lg) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.h-whitespace-height {
  height: calc(100vh - 118px);
}

.h-whitespace-height-minus-lang {
  height: calc(100vh - 163px);
}

.mnh-57 {
  min-height: 57px;
}

.no-decoration {
  &, &:hover {
    text-decoration: none;
  }
}

.no-outline {
  &, &:hover, &:focus, &:active {
    box-shadow: none;
    outline: none;
  }
}

.object-fit {
  &__contain {
    object-fit: contain;
  }
  &__cover {
    object-fit: cover;
  }
}

.object-position {
  &__center {
    object-position: center;
  }
}

.lh-20 {
  line-height: 20px;
}

.pull-y-1 {
  margin-top: -5px;
  margin-bottom: -5px;
}

.pull-t-5 {
  margin-top: -20px;
}

.row-2 {
  margin-left: -10px;
  margin-right: -10px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.badge-light {
  background: #F2F3F8;
  border-radius: 16px;
  color: #343537;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 8px;
}


.text-light-blue {
  color: #5569DA;
}

.overflow-initial {
  overflow: initial !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-tel-input {
  .flag-dropdown { display: none; }
}
